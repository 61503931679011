import React, { useEffect, useState } from "react";
import { graphql, StaticQuery } from "gatsby"
import PropTypes from "prop-types";

import Layout from "../../components/layout";
import SEO from "../../components/seo";

import SubHero from '../../components/elements/sub-hero/sub-hero';
import ListItem from '../../components/elements/list-item/list-item';
import SubIntro from '../../components/elements/sub-intro/sub-intro';
import BookCta from '../../components/elements/book-cta/book-cta';


const ServicePage = ({ data }) => {

  let [loaded, setLoaded] = useState(false)

  useEffect(() => {
    setLoaded(true)
  }, [])

  return (
    <Layout pageType='beauty'>
      <SEO
        keywords={[`Massage Ely`, `Massaging Ely`, `Head Massage Ely`, `Back Massage Ely`, `Hot Stone Massage Ely`]}
        title="Massage at our Stunning Beauty Salon based in Ely, Cambridgeshire"
        description="Relax and unwind with one of our luxurious Pure Massages.. The massage can help sore muscles or help you relax from a long stressful day."
      />
      <SubHero 
        marginBottom
        loaded={loaded}
        bgImage={data.hero.childImageSharp.fluid}
        data={{
          title: 'Massage',
          subTitle: '*Time allocated includes consultation',
          twoCol: [
            <div className="font-thin" key={1}>
              <ListItem text={<span>Head Massage (20 mins)<span className="text-sm"></span>: <b className="font-semibold">£34</b></span>} />
              <ListItem text={<span>Half Body Massage (20 mins)<span className="text-sm"></span>: <b className="font-semibold">£45</b></span>} />
              <ListItem text={<span>Full Body Massage (50 mins)<span className="text-sm"></span>: <b className="font-semibold">£78</b></span>} />
            </div>,
            <div className="font-thin" key={2}>
              <ListItem text={<span>Half Body Hot Stone Massage (20 mins)<span className="text-sm"></span>: <b className="font-semibold">£51</b></span>} />
              <ListItem text={<span>Full Body Hot Stone Massage (50 mins)<span className="text-sm"></span>: <b className="font-semibold">£81</b></span>} />
              <ListItem text={<span>Hopi Ear Candle & Massage <span className="text-sm"></span>: <b className="font-semibold">£56</b></span>} />
            </div>
          ]
        }}
      />
      <SubIntro 
        text={<p>Relax and unwind at Ely Beauty and Skin Clinc with one of our luxurious massages. Ranging from 20 minutes to 60 minutes, enjoy everything from a head massage to a full body hot stone massage experience.</p>}
      />
      <BookCta discount="https://gift-cards.phorest.com/salons/houseofpureltd" link="https://phorest.com/book/salons/houseofpureltd#/service-category?id=AE-kkLL2mJJR4T9ECteQlA" phone="01353 661 321" email="elybeautyandskinclinic@gmail.com" bg={data.bookbg.childImageSharp.fluid} />
    </Layout>
  );
}

ServicePage.propTypes = {
  data: PropTypes.object,
};

const indexQuery = graphql`
  query {
    hero: file(relativePath: { eq: "massage-bg.jpg" }) {
      childImageSharp {
        fluid(quality: 60, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    bookbg: file(relativePath: { eq: "booking-bg-blurry.jpg" }) {
      childImageSharp {
        fluid(quality: 50, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

const ServiceComponent = props => (
  <StaticQuery
    query={indexQuery}
    render={data => (
      <ServicePage props data={data} {...props} />
    )}
  />
)

ServiceComponent.displayName = "ServiceComponent"

export default ServiceComponent
